import request from "../lib/request";
import Cookie from "js-cookie";
import { redirect } from "react-router-dom";
export const engagementCategoryListApi = async (url) => {
  let result;
  try{
    const { data } = await request.get(url);
    result = data;
  }
  catch(er){
    if(er?.response.data?.status.toLowerCase() == "error"){
      if(er?.response.data?.error_message.toLowerCase() == 'unauthorized'){
        Cookie.remove('token');
        Cookie.remove('role');
        console.log('login error from list APi');
        redirect("/login");
        }else{
          result = er;
        }
    }
   
  }
 
  return result;
}

export const engagementCategoryCommentsApi = async (url) => {
  let result;
  try{
    const { data } = await request.get(url);
    result = data;
  }
  catch(er){
    if(er?.response.data?.status.toLowerCase() == "error"){
      if(er?.response.data?.error_message.toLowerCase() == 'unauthorized'){
        Cookie.remove('token');
        Cookie.remove('role');
        console.log('login error from list APi');
        redirect("/login");
        }else{
          result = er;
        }
    }
   
  }
 
  return result;
}
export const engagementCategoryScoreApi = async (url) => {
  let result;
  try{
    const { data } = await request.get(url);
    result = data;
  }
  catch(er){
    if(er?.response.data?.status.toLowerCase() == "error"){
      if(er?.response.data?.error_message.toLowerCase() == 'unauthorized'){
        Cookie.remove('token');
        Cookie.remove('role');
      console.log('login error from Scope APi');
       redirect("/login");
      }else{
        result = er;
      }
    }

  }
 
  return result;
}
export const getPulseValueApi = async (url) => {
  let result;
  try{
    const { data } = await request.get(url);
    result = data;
  }
  catch(er){
   if(er?.response.data?.status.toLowerCase() == "error"){
      if(er?.response.data?.error_message.toLowerCase() == 'unauthorized'){
        Cookie.remove('token');
        Cookie.remove('role');
        console.log('login error from Pulse Value APi');
        redirect("/login");
      }else{
        result = er;
      }
    }
   
  }
 
  return result;
}

export const sendEngagementCategoryRequest = async (url,payload) => {
  let result;
  try{
    const { data } = await request.post(url,payload);
    result = data;
  }
  catch(er){
    if(er?.response.data?.status.toLowerCase() == "error"){
      if(er?.response.data?.error_message.toLowerCase() == 'unauthorized'){
        Cookie.remove('token');
        Cookie.remove('role');
        console.log('login error from Pulse Value APi');
        redirect("/login");
      }else{
        result = er;
      }
      // console.log('hello error ', er);
    }
   
  }
 
  return result;
}

export const setPulseValueApi = async (url) => {
  let result;
  try{
    const { data } = await request.post(url);
    console.log('data ', data)
    result = data;
  }
  catch(er){
    if(er?.response.data?.status == "ERROR"){
      Cookie.remove('token');
      Cookie.remove('role');
      redirect("/login");
    }
   
  }
  return result;
}