import * as React from 'react';
import { useState } from 'react';
import { useNavigate, useLocation, matchPath, matchRoutes } from 'react-router-dom';


export default function TopBar() {
    const navigate = useNavigate();
    return (
        <>
            <div className="right-menu">
                <ul>
                    <li onClick={()=>{navigate('/employee-dashboard')}}  className={matchPath('/employee-dashboard', useLocation().pathname)? 'activeMenu' : ''}>
                        Employee
                    </li>
                    <li onClick={()=>{navigate('/manager-dashboard')}} className={matchPath('/manager-dashboard', useLocation().pathname)? 'activeMenu' : ''}>
                        Manager                    
                    </li>

                </ul>
            </div>
        </>

    );
}
