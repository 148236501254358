import React from "react";
import "./login.scss";

import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";

import FormHelperText from "@mui/material/FormHelperText";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";

import { useFormik } from "formik";
import * as Yup from "yup";
import { Navigate, useNavigate } from "react-router-dom";

import LockIcon from "@mui/icons-material/Lock";

import { LoginApi } from "../../service/api";

import Cookie from "js-cookie";


import { app, authentication } from '@microsoft/teams-js';

import { useSnackbar } from 'notistack';

import Cookies from 'universal-cookie';


const Login = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  React.useEffect(() => {
    document.title = "Manager IQ | Login";
  }, []);
  const [values, setValues] = React.useState({});
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      clientId: "",
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      clientId: Yup.string().trim("Please enter valid client Id"),
      // username: Yup.string().min(2).max(200).email().required("Please enter valid email"),
      username: Yup.string()
        .min(2)
        .max(200)
        .required("Please enter valid email"),
      password: Yup.string().required("Please enter password"),
    }),

    // ============= form submit =========
    onSubmit: async (data) => {
      const response = await LoginApi(data);
      if(response.token != false){
        let variant = 'success';
        enqueueSnackbar('Login successfully', {variant});
        // Cookie.set('token', response.token, 4);
        // Cookie.set('role', response.manager, 4);

        const cookies = new Cookies();
        cookies.set('token', response.token, {secure: true, sameSite: 'none'});
        cookies.set('role', response.manager, {secure: true, sameSite: 'none'});


        const user = response.manager != undefined ? (response.manager == true ? 'manager' : 'employee') : false;
        if(user){
          navigate('/employee-dashboard')
        }
        // navigate('/employee-dashboard');
        // if(user == 'manager'){
        //   navigate('/manager-dashboard')
        // }else if(user == 'employee'){
        //   navigate('/employee-dashboard')
        // }
      }else{
        let variant = 'error';
        enqueueSnackbar('Login error', {variant});
      }
    },
  });

  const handleClickShowPassword = () => {
    setValues({
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Card className="login-wrapper">
      <div className="login-wrapper-inner">
        <CardHeader title="Login" />
        <CardContent className="login-formwrap">
          <form autoComplete="off" onSubmit={formik.handleSubmit}>
            <FormControl fullWidth sx={{ mb: 3 }}>
              <label>Client ID</label>
              <TextField
                variant="outlined"
                id="clientId"
                name="clientId"
                error={formik.touched.clientId && formik.errors.clientId}
                placeholder="CID - Sample Text Here"
                value={formik.values.clientId}
                onChange={formik.handleChange}
                helperText={formik.touched.clientId && formik.errors.clientId}
              />
            </FormControl>
            <FormControl fullWidth sx={{ mb: 3 }} variant="outlined">
              <label>Username (Must be email address)</label>
              <OutlinedInput
                error={formik.touched.username && formik.errors.username}
                id="username"
                name="username"
                type="text"
                placeholder="info@mail.com"
                value={formik.values.username}
                onChange={formik.handleChange}
              />
              {formik.touched.username && formik.errors.username ? (
                <FormHelperText error={formik.errors.username}>
                  {formik.errors.username}
                </FormHelperText>
              ) : null}
            </FormControl>
            <FormControl fullWidth sx={{ mb: 1 }} variant="outlined">
              <label>Password</label>
              <OutlinedInput
                error={formik.touched.password && formik.errors.password}
                id="password"
                name="password"
                type={values.showPassword ? "text" : "password"}
                value={formik.values.password}
                onChange={formik.handleChange}
                startAdornment={
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faLock} />
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {formik.touched.password && formik.errors.password ? (
                <FormHelperText error={formik.errors.password}>
                  {formik.errors.password}
                </FormHelperText>
              ) : null}
            </FormControl>
            {/* <FormControl fullWidth sx={{ mb: 4 }} variant="outlined">
              <div className="rememberMe-wrap">
                <FormControlLabel control={<Checkbox />} label="Remember Me" />
                <Link href="#">Forgot Password?</Link>
              </div>
            </FormControl> */}
            <FormControl fullWidth variant="outlined" sx={{ mt: 5 }}>
              <Button
                type="submit"
                size="100%"
                variant="contained"
                className="login-btn"
              >
                Login
              </Button>
            </FormControl>
          </form>
        </CardContent>
      </div>
    </Card>
  );
};

Login.propTypes = {};

Login.defaultProps = {};

export default Login;
