import React, { useEffect, useState } from "react";
// import PropTypes from "prop-types";
import "./pulse-rating.scss";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import CircleIcon from "@mui/icons-material/Circle";
import { PulseComments } from "../modals/modals";
import { getPulseValueAction, setPulseValueAction } from "../../actions/engagement";
import { setPulseValueList } from "../../reducer/engagementPillars";
import { useDispatch, useSelector } from "react-redux";
import * as _ from "lodash";
import moment from 'moment' 

const PulseRating = () => {
  const dispatch = useDispatch();
  const [pulseRate, setpulseRate] = useState("");
  const [ResponseSave, setResponseSave] = useState("");
  const[isShowMessage , setIsShowMessage] = useState(true)
  const [responseHeaderMessage, setresponseHeaderMessage] = useState(
    "Choose a number to select your response"
  );
  // const vaklue= useSelector((state) => state.engagementCategory);
  const { pulseValueList } = useSelector((state) => state.engagementCategory);
 
  // const [sliderCallback, setSliderCallback] = useState('');
  const sliderCallback = React.useRef(null);
  const currentDate = new Date();
  const n = 10;
  const handleSaveResponse = async() => {
    let d = currentDate.setDate(currentDate.getDate() + 7);
    if (ResponseSave == "") {
      setResponseSave(d);
      let value = await setPulseValueAction('emp/pulse/'+pulseRate);
      console.log(value ,"deedeedede")
      setresponseHeaderMessage(
        `Your response was submitted on ${customDateFormat(new Date())}`
      );
    }
  };
  const customDateFormat = (e) => {
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(e);
  };

  const handleFunction = () => {
   
    // console.log('hello click');
    // ResponseSave != "" ? React.useRef : false
    // sliderCallback.current = '';

    if (ResponseSave != "") {
      setIsShowMessage(true)
      setresponseHeaderMessage(
        `Your response was already submitted on 
       ${ResponseSave}
        `
      );
      // alert(responseHeaderMessage.current)
    }
  };

  const pulseActive = (e) =>{
    setpulseRate(e.target.value);
    // console.log("e.target.value ", e.target.value)
  };
  const pulseRatingListDispatch = async () => {
    let value = await getPulseValueAction('emp/pulse');
    dispatch(setPulseValueList(value));
  }

  useEffect(() => {
    pulseRatingListDispatch();
  },[])

  const getLatestDateValues = (e,f) => {
    if(e.length > 0 ){
        let latest = _.map(_.sortBy(e, o => Date.parse(o.date)),'update_time').reverse()[0];
        let result = e.find((item) => item.update_time == latest);
        const date1 = new Date();
        const date2 = new Date(result?.update_time);
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
        return {result , diffDays};
      }
    return {};
    
  }
  useEffect(() => {
    if(!!pulseValueList){
     let values= getLatestDateValues(pulseValueList);
     var objToday = new Date()
// let	weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
// const	dayOfWeek = weekday[objToday.getDay()]
    const first = objToday.getDate() - objToday.getDay() + 1;
    const monday = new Date(objToday.setDate(first)).setHours(8, 0, 0);;
    let isMonday = new Date(monday);
    let diffTime = new Date(values?.result?.update_time);
    if(isMonday.getTime()/1000< diffTime.getTime()/1000){
      setpulseRate(values?.result?.pulse.toString());
      setIsShowMessage(false)
       setResponseSave(moment(values?.result?.update_time).format("MM/DD/yyyy"))
    }
    //  if(!!values && values?.diffDays <7){
      // setpulseRate(values?.result?.pulse.toString());
      // setIsShowMessage(false)
      //  setResponseSave(moment(values?.result?.update_time).format("MM/DD/yyyy"))
    //  }
    }
  },[pulseValueList])
  
function getMonday() {
  const d = new Date();
  d.setDate(d.getDate() + (1 + 7 - d.getDay()) % 7);
  return moment(d).format("MM/DD/yyyy")
}

  return (
    <Card className="pulse_rating-wrapper">
      <CardHeader title="How are you feeling about your work this week?" />
      <CardContent>
        {(!!ResponseSave && !isShowMessage)?
        <Typography>Your response was submitted on {ResponseSave}</Typography>
        :
        <Typography>{responseHeaderMessage}</Typography>}
        <Box
          // maxWidth={650}
          mx="auto"
          onClick={handleFunction}
        >
          <RadioGroup row>
            {[...Array(n)].map((elementInArray, index) => (
              <FormControlLabel
                key={index}
                value={index + 1}
                disabled={ResponseSave != "" ? true : false}
                control={
                  <Radio
                  value={index + 1}
                  checked={pulseRate==index + 1? true: false}
                  color="white"
                  disableRipple
                  checkedIcon={<CircleIcon />}
                  icon={<CircleIcon />}
                  onClick={pulseActive}
                  />
                }
                label={index + 1}
              />
            ))}
          </RadioGroup>
        </Box>
      </CardContent>
      <CardActions>
        {ResponseSave != "" ? (
          <div className="checkIn-message">
            <Typography>Next Pulse Check-In</Typography>
            <Typography>Available {getMonday()}</Typography>
          </div>
        ) : (
          ""
        )}
        <Button
          type="submit"
          size="100%"
          variant="outlined"
          color="white"
          disabled={(ResponseSave != "" || pulseRate == '') ? true : false }
          className="btn-white"
          sx={{
            padding: "10px 35px",
          }}
          onClick={handleSaveResponse}
        > 
          Save Response
        </Button>
        <div className="add-comment-btn">
          <PulseComments />
        </div>
      </CardActions>
    </Card>
  );
};

PulseRating.propTypes = {};

PulseRating.defaultProps = {};

export default PulseRating;
