import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Login from "./components/login/login";
import EmployeeDashboard from "./pages/employee-dashboard";
import ManagerDashboard from "./pages/manager-dashboard";
import Profile from "./pages/profile";
import ManagerChat from "./pages/manager-chat";

import { NavigateBeforeOutlined } from "@mui/icons-material";
import Cookie from "js-cookie";
import Protected, { LoginRoute , AuthGuard} from './components/nav/Protected'

import Cookies from 'universal-cookie';

import { SnackbarProvider } from "notistack";



function App() {
  useEffect(()=>{
  // console.log('Token ', Cookies.get('token'));
})
  const userDashBoard = Cookie.get('role') != undefined ? (Cookie.get('role') == true ? 'manager' : 'employee') : false;
return (
  <SnackbarProvider>
    <Router>
      <Routes>
        <Route element={<LoginRoute />}>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Login />} />
        </Route>
        {/* ==== Login check ========== */}
        <Route element={<AuthGuard />}>
          <Route element={<EmployeeDashboard/>} path="/employee-dashboard" exact/>
        </Route>
        <Route element={<Protected role="manager" />}>
          <Route element={<ManagerDashboard/>} path="/manager-dashboard"/>
          <Route element={<Profile/>} path="/profile"/>
          <Route element={<ManagerChat/>} path="/manager-chat"/>
        </Route>
      </Routes>
    </Router>
  </SnackbarProvider>
  );
}

export default App;
