import React from "react";
import "./notifications.scss";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import employeeImg1 from "../../assets/images/joanie-doe.png";
import employeeImg2 from "../../assets/images/samantha-smith.png";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Chip from "@mui/material/Chip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation } from "@fortawesome/free-solid-svg-icons";
import { EmployeeComment } from "../modals/modals";

const Notifications = () => {
  const employee_data = [
    {
      id: 1,
      name: "Samantha Smith",
      profileImg: employeeImg1,
      pillarName: "Weekly Pulse",
      lastScore: 4,
      currentScore: 9,
      comment:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit dolor sit. Maecenas ac lacus vitae nibh porta lobortis laoreet felis. Lorem ipsum dolor sit amet, consectetur adipiscing elit dolor sit. Maecenas ac lacus vitae nibh porta.",
    },
    {
      id: 2,
      name: "Alex Johnson",
      profileImg: employeeImg2,
      pillarName: "Feedback",
      lastScore: 4,
      currentScore: 2,
      comment: "",
    },
    {
      id: 3,
      name: "John Longest",
      profileImg: employeeImg1,
      pillarName: "Opportunity",
      lastScore: 3,
      currentScore: 8,
      comment: "",
    },
    {
      id: 4,
      name: "Joanie Doe",
      profileImg: employeeImg2,
      pillarName: "Recognition",
      lastScore: 3,
      currentScore: 8,
      comment: "",
    },
    {
      id: 5,
      name: "George Brighton",
      profileImg: "",
      pillarName: "Sense of Purpose",
      lastScore: 2,
      currentScore: 5,
      comment: "",
    },
    {
      id: 6,
      name: "Joanie Doe",
      profileImg: employeeImg2,
      pillarName: "Opportunity",
      lastScore: 4,
      currentScore: 2,
      comment: "",
    },
    {
      id: 7,
      name: "George Brighton",
      profileImg: employeeImg2,
      pillarName: "Opportunity",
      lastScore: 4,
      currentScore: 2,
      comment: "",
    },
    {
      id: 8,
      name: "George Brighton",
      profileImg: employeeImg2,
      pillarName: "Opportunity",
      lastScore: 4,
      currentScore: 2,
      comment: "",
    },
    {
      id: 9,
      name: "George Brighton",
      profileImg: employeeImg2,
      pillarName: "Opportunity",
      lastScore: 4,
      currentScore: 2,
      comment: "",
    },
    {
      id: 10,
      name: "Alex Johnson",
      profileImg: "",
      pillarName: "Opportunity",
      lastScore: 4,
      currentScore: 2,
      comment: "",
    },
  ];
  function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }
  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }

  
  const data = employee_data;

  return (
    <>
      <Card className="notifications-wrapper">
        <CardHeader title="Notifications" />
        <CardContent>
          <List>
            {employee_data?.map((data, i) => (
              <>
                <ListItem key={i}>
                  <Stack className="notifications-user">
                    <ListItemAvatar>
                        { data.profileImg ? (
                            <Avatar alt={data.name} src={data.profileImg} />
                          ) : (
                            
                            <Avatar {...stringAvatar(data.name)} />
                          )
                        }
                      
                    </ListItemAvatar>
                    <ListItemText primary={data.name} secondary="12/18/22" />
                  </Stack>
                  <Stack className="notifications-details">
                    <Box
                      component="span"
                      // className="details-left"
                      className={
                        data.currentScore < data.lastScore
                          ? "details-left low-score"
                          : "details-left"
                      }
                    >
                      {data.currentScore < data.lastScore && (
                        <>
                          <FontAwesomeIcon icon={faExclamation} />
                        </>
                      )}
                      <Typography variant="h6">{data.pillarName}</Typography>
                      {data.comment && <EmployeeComment data={{
                        btnText: "(Comment Added)",
                      }} />
                      }
                    </Box>
                    <Box component="span" className="details-right">
                      <Typography>{data.lastScore} to</Typography>
                      <Chip
                        label={data.currentScore}
                        color="primary"
                        className={
                          data.currentScore < data.lastScore ? "red" : "green"
                        }
                      />
                    </Box>
                  </Stack>
                </ListItem>
              </>
            ))}
          </List>
        </CardContent>
      </Card>
    </>
  );
};

export default Notifications;
