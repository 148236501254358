import React, { useEffect, useState } from "react";
import ChangeTracking from "../components/change-tracking/change-tracking";
import EmployeeProfile from "../components/employee-profile/employee-profile";
import EngagementPillars from "../components/engagement-pillars/engagement-pillars";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Charts from "../components/charts/charts";
import Stack from "@mui/material/Stack";
import PillarIndicators from "../components/pillar-indicators/pillar-indicators";
import TopBar from "../components/top-bar/top-bar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";


const Profile = () => {

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  function getWindowSize() {
    const { innerWidth } = window;
    return { innerWidth };
  }

  return (
    <>
      <div className="rootWrapper">
        <div className="breadcrumb-wrap">
          {/* <h4 className="breadcrumb-title"><FontAwesomeIcon icon={faCircleArrowLeft} />Manager Dashboard / <strong>Samantha Smith’s Profile</strong></h4> */}
          <h4 className="breadcrumb-title"><FontAwesomeIcon icon={faCircleArrowLeft} />Back to Manager Dashboard</h4>
          <TopBar />
        </div>
        <Container maxWidth className="rootContainer" >
          
          <Grid>
            <Stack className="topRow" sx={{ display: "grid" }}>
              <Grid item className="topLeft">
                <EmployeeProfile />
                {windowSize.innerWidth >= 992 &&
                  <PillarIndicators />
                }
              </Grid>
              <Grid item className="topRight">
                <div className="topRight-inner">
                  <Charts />
                  <ChangeTracking />
                </div>
                {windowSize.innerWidth >= 992 &&
                  <div className="topRight-inner">
                    <EngagementPillars />
                  </div>
                }
              </Grid>
            </Stack>
            {windowSize.innerWidth <= 991 &&
              <Stack className="bottomRow">
                {windowSize.innerWidth <= 991 &&
                  <PillarIndicators />
                }
                <EngagementPillars />
              </Stack>
            }
          </Grid>
        </Container>
      </div>

    </>
  );
};

export default Profile;
