import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import RangeSlider from "../range-slider/range-slider";
import { EmployeeComments, CommentListAdd } from "../modals/modals";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import Button from "@mui/material/Button";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import Skeleton from '@mui/material/Skeleton';
import * as _ from "lodash";
import { useSnackbar } from 'notistack';

import ClickAwayListener from '@mui/material/ClickAwayListener';


export const PillarCard = ({data,index,score,callBackEvent,comments,callBackCommentEvent}) => {
  const [open, setOpen] = useState("");
  const scores = score;
  const comment = comments;
  const item = data;
  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  const [getScore,setScore] = useState(scores?.score != '' ? scores?.score : null);
  const [getTarget,setTarget] = useState(scores?.target != '' ? scores?.target : null);
  const [getComment,setComment] = useState(comment?.comment != '' ? comment?.comment : '');

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleSubmitComment = (e) => {
    // if((e != undefined && e != '') || (_.isEmpty(comments) == false && e == '')){
      if(e != undefined){
      let record = {
        "engagement_category": item?.code,
        "comment": e
      };
      callBackCommentEvent(record);
      setComment(e);
    }
    // else{
    //   let variant = 'error';
    //   enqueueSnackbar(`No change found`, {variant});
    // }
  }

  const triggerCallback = (item,data,code) => {
    if(data != undefined){
      let record = {
        "engagement_category": code,
        "score": (item == 'score' ? (data > 0 ? data : 1) : (getScore > 0 ? getScore : 1)),
        "target": (item == 'target' ? (data > 0 ? data : 1) : (getTarget > 0 ? getTarget : 1))
      };
      callBackEvent(record);
    }
  }
  
  
  const HTMLContent = (values) => {
    return (
      <>
        <Card sx={{ borderRadius: "10px" }}>
          <CardContent sx={{ px: "25px" }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ borderBottom: "1px solid #B2B2B2", mb: "10px" }}
            >
              <Typography variant="h6" sx={{ color: "#000", fontWeight: "bold", mb: "10px", }} >
                {values.title}
              </Typography>
              <IconButton
                aria-label="close"
                sx={{
                  color: "#707070",
                }}
                onClick={() => {
                  setOpen("");
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <Stack direction="row">
              <Typography
                sx={{
                  color: "#000",
                }}
              >
                {values.content}
              </Typography>
            </Stack>
          </CardContent>
        </Card>
      </>
    );
  };

  const LightTooltip = styled(({ className, ...props }) => (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip {...props} classes={{ popper: className }} placement="top" />
    </ClickAwayListener>

  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "transparent",
      padding: "0",
      maxWidth: "490px",
      width: "calc(100% - 20px)",
      marginLeft: "auto",
      marginRight: "auto",
    },
  }));
  // const callbackData = 
  
 
    // return data.map((item, index) => {
        return (
          <Box
          sx={{
            width: "100%",
            minWidth: "100%",
          }}
          className="engagementPillar-sliderBox"
        >
          <Card
            sx={{
              width: "100%",
              boxShadow: "none",
              borderRadius: "10px",
              textAlign: "center",
              border: "1px solid #22A6DE",
            }}
          >

            <CardContent sx={{ py: 1.5 }}>
              <Typography>10</Typography>
              <Stack
                sx={{ height: 258, justifyContent: "center" }}
                spacing={1}
                direction="row"
                className={getTarget === getScore ? "equalPillar" : ""}
              >
                {
                  item == '' || item == undefined ? <Skeleton width={40} height={258} /> :
                  <>
                  <RangeSlider
                  settings={{
                    height: "100%",
                    width: 40,
                    maxWidth: "40px",
                    min: 1,
                    max: 10,
                    color: "transparent",
                    borderRadius: "6px",
                    defaultValue: scores?.target,
                    valueLabelDisplay: "on",
                    orientation: "vertical",
                    marks: true,
                    disableSwap:false,
                  
                    className:"targetRange",
                  }}
                  eventTriggered={(value) =>{triggerCallback('target',value,data?.code); setTarget(value)}}
                />
                <RangeSlider
                  settings={{
                    height: "100%",
                    width: 40,
                    maxWidth: "40px",
                    min: 1,
                    max: 10,
                    color: "transparent",
                    borderRadius: "6px",
                    defaultValue: scores?.score,
                    valueLabelDisplay: "on",
                    orientation: "vertical",
                    marks: true,
                    disableSwap:false,
                  
                    className:"currentRange",
                  }}
                  eventTriggered={(value) =>{triggerCallback('score',value,data?.code); setScore(value)}}
                />
                  </>
                }
                

              </Stack>
              <Typography>1</Typography>
            </CardContent>
            <CardActions sx={{ padding: 0 }} className={_.isEmpty(getComment) == false ? "" : "disabled"} >
              <EmployeeComments value={getComment} rating={getScore} handleSaveComment={handleSubmitComment} />
            </CardActions>
          </Card>
          <Typography
            variant="h6"
            sx={{
              color: "#000",
              fontSize: "0.9rem",
              lineHeight: "1.4",
              fontWeight: "bold",
              maxWidth: "100px",
              mx: "auto",
              mt: "15px",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
            }}
          >
            {item?.name}
            <LightTooltip
             placement="top"
              PopperProps={{
                disablePortal: true,
              }}
              onClose={() => {
                setOpen("");
              }}
              open={open !== "" && open === index + 1 ? true : false}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={<HTMLContent title={item?.name} content={item?.info} />}
              arrow
            >
              <IconButton
                aria-label="Info"
                onClick={() => {
                  setOpen(index + 1);
                }}
                sx={{ color: "#707070", fontSize: "0.9rem", ml: "1px", mb: "3px", py: "0px" }}
              >
                <InfoIcon sx={{
                  fontSize: "0.9rem",
                }}
                />
              </IconButton>
            </LightTooltip>
          </Typography>
        </Box>
        );

        
    //   });
    
}
