import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    engagementCategoryList:{},
    engagementCategoryScore:{},
    pulseValueList:[]
}
export const engagementCategorySlice = createSlice({
    name:'engagementCategory',
    initialState,
    reducers:{
        setengagementCategoryList(state, action){
            state.engagementCategoryList = action.payload
        },
        setengagementCategoryScore(state, action){
            state.engagementCategoryScore = action.payload
        },
        setPulseValueList(state, action){
            state.pulseValueList = action.payload
        },
        setengagementCategoryComments(state, action){
            state.engagementCategoryComments = action.payload
        },
        setengagementChengedValue(state,action){
            state.engagementChangedValue = action.payload
        }
    }
})

export const { setengagementCategoryList, setengagementCategoryScore, setPulseValueList, setengagementCategoryComments, setengagementChengedValue } = engagementCategorySlice.actions;
export default engagementCategorySlice.reducer;