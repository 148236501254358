import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import EmployeeProfile from "../components/employee-profile/employee-profile";
import Chat from "../components/chat/chat"
import ConversationHistory from "../components/conversation-history/conversation-history"
import TopBar from "../components/top-bar/top-bar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";



const ManagerChat = () => {
  return (
    <>
      <div className="rootWrapper">
        <div className="breadcrumb-wrap">
          <h4 className="breadcrumb-title"><FontAwesomeIcon icon={faCircleArrowLeft} />Manager Dashboard / Samantha Smith’s Profile</h4>
          <TopBar />
        </div>
        <Container maxWidth className="rootContainer">
          <Grid container className="managerChatPageContainer" sx={{ display: "grid" }}>
            <Grid item className="profileBox">
              <EmployeeProfile />
            </Grid>
            <Grid item className="chatBox">
              <Chat />
            </Grid>
            <Grid item className="historyBox">
              <ConversationHistory />
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default ManagerChat;
