import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// import reportWebVitals from "reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import { Provider } from "react-redux";

import { store } from "./store";

const theme = createTheme({
  typography: {
    fontFamily: [
      "Helvetica",
    ].join(","),
  },
  palette: {
    type: "light",
    primary: {
      main: "#225FA0",
      dark: "#074487",
    },
    success:{
      main: "#46A800",
    },
    error:{
      main: "#FF3E3E",
    },
    neutral: {
      main: "#E3E3E3",
      contrastText: "#707070",
    },
    white: {
      main: "#ffffff",
      dark: "#fff",
    },
    black: {
      main: "#000",
      dark: "#000",
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <App />
    </Provider>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
