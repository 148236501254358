import { engagementPillars } from "../service/engagement";

import request from "../lib/request";
import { setEngagementPillars } from "../reducer/engagementPillars";
import { Dispatch, SetStateAction } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { engagementCategoryListApi, engagementCategoryScoreApi, getPulseValueApi, setPulseValueApi, sendEngagementCategoryRequest, engagementCategoryCommentsApi } from "../service/engagementApi";

export const engagementCategoryListAction = async (url) => {
    const response = await engagementCategoryListApi(url);
    // console.log(response.data)
    return response;
}
export const engagementCategoryScoreAction = async (url) => {
    const response = await engagementCategoryScoreApi(url);
    return response;
}
export const getPulseValueAction = async (url) => {
    const response = await getPulseValueApi(url);
    return response;
}
export const sendEngagementCategoryRequestAction = async (url,payload) => {
    const response = await sendEngagementCategoryRequest(url,payload);
    return response;
}
export const setPulseValueAction = async (url) => {
    const response = await setPulseValueApi(url);
    return response;
}
export const engagementCategoryCommentsAction = async (url) => {
    const response = await engagementCategoryCommentsApi(url);
    return response;
}