import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./modals.scss";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import "./../../main.scss";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
// Fontawesome imports
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { faComment } from "@fortawesome/free-regular-svg-icons";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

import * as _ from "lodash";
import Skeleton from "react-loading-skeleton";

import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "calc(100% - 30px)",
  maxWidth: "578px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 0,
  borderRadius: "20px",
  overflow: "hidden",
};

/* Single comments popup Start */

export const EmployeeComments = ({value, rating, handleSaveComment}) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [getComment, setComment] = useState(_.isEmpty(value) == false? value : '');

  const handleValueToSend = (e) => {
    setComment(e.target.value);
  }
 
  return (
    <>
      <Button
        className="commentButton"
        onClick={handleOpen}
        sx={{
          minHeight: "42px",
          width: "100%",
          fontSize: "15px",
          color: "#000",
          borderRadius: "0",
          textTransform: "capitalize",
        }}
      >
        <svg
          height="20pt"
          viewBox="-21 -47 682.66669 682"
          width="20pt"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m552.011719-1.332031h-464.023438c-48.515625 0-87.988281 39.464843-87.988281 87.988281v283.972656c0 48.414063 39.300781 87.816406 87.675781 87.988282v128.863281l185.191407-128.863281h279.144531c48.515625 0 87.988281-39.472657 87.988281-87.988282v-283.972656c0-48.523438-39.472656-87.988281-87.988281-87.988281zm50.488281 371.960937c0 27.835938-22.648438 50.488282-50.488281 50.488282h-290.910157l-135.925781 94.585937v-94.585937h-37.1875c-27.839843 0-50.488281-22.652344-50.488281-50.488282v-283.972656c0-27.84375 22.648438-50.488281 50.488281-50.488281h464.023438c27.839843 0 50.488281 22.644531 50.488281 50.488281zm0 0" />
          <path d="m171.292969 131.171875h297.414062v37.5h-297.414062zm0 0" />
          <path d="m171.292969 211.171875h297.414062v37.5h-297.414062zm0 0" />
          <path d="m171.292969 291.171875h297.414062v37.5h-297.414062zm0 0" />
        </svg>
        Comment
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 300,
        }}
        className="customPopup"
      >
        <Fade in={open}>
          <Box sx={style}>
            <Card
              sx={{
                boxShadow: "0px 2px 12px #00000065;",
                borderRadius: "10px",
              }}
            >
              <div className="popupHeader">
                <h4>Add comment</h4>
                <IconButton aria-label="close" onClick={() =>{setOpen(false)}}>
                  <FontAwesomeIcon icon={faXmark} />
                </IconButton>
              </div>
              <CardContent
                sx={{ p: "0px 20px 20px" }}
                className="commentBoxWrap"
              >
                <Stack className="commentTopInfo">
                  <Typography sx={{ color: "#000", fontWeight: "bold" }}>
                    Feedback
                  </Typography>
                  <Box
                    component="span"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography
                      sx={{
                        color: "#707070",
                        fontSize: "12px",
                        fontWeight: "bold",
                        mr: "8px",
                      }}
                    >
                      Your Rating
                    </Typography>
                    <Chip label={!!rating?rating:0} color="primary" />
                  </Box>
                </Stack>
                <Box
                  component="form"
                  sx={{ "& .MuiTextField-root": { width: "100%" } }}
                  noValidate
                  autoComplete="off"
                >
                  
                  <Stack sx={{ margin: "1px 0px" }}>
                    <TextField 
                      placeholder="Leave comment (optional)"
                      multiline
                      value={getComment}
                      onChange={handleValueToSend}
                    />
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    textAlign="right"
                    sx={{ mt: "25px" }}
                  >
                    <Button variant="text" className="btn-text " onClick={()=>{setComment('')}}>
                      Clear
                    </Button>
                    <Button variant="contained" className="btn-primary ml-2" onClick={()=>{
                      handleSaveComment(getComment); 
                      setOpen(false)}}>
                      Save Comment
                    </Button>
                  </Stack>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

/* Single comments popup End */

// pulse add comment modal
export const PulseComments = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <Button
        size="100%"
        color="white"
        onClick={handleOpen}
        className="btn-primary"
        sx={{
          textTransform: "capitalize",
          textDecoration: "underline",
          padding: "0px",
        }}
      >
        Add Comment
        <FontAwesomeIcon icon={faComment} />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 300,
        }}
        className="customPopup"
      >
        <Fade in={open}>
          <Box sx={style}>
            <Card
              sx={{
                boxShadow: "0px 2px 12px #00000065;",
                borderRadius: "10px",
              }}
            >
              <div className="popupHeader">
                <h4>Add comment</h4>
                <IconButton aria-label="close" onClick={handleClose}>
                  <FontAwesomeIcon icon={faXmark} />
                </IconButton>
              </div>
              <CardContent sx={{ p: "20px 20px" }}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    mb: "10px",
                  }}
                >
                  <Typography sx={{ color: "#000", fontWeight: "bold" }}>
                    Weekly Pulse
                  </Typography>
                  <Box
                    component="span"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography
                      sx={{
                        color: "#707070",
                        fontSize: "12px",
                        fontWeight: "bold",
                        mr: "8px",
                      }}
                    >
                      Your Rating
                    </Typography>
                    <Chip label="5" color="primary" />
                  </Box>
                </Stack>
                <Box
                  component="form"
                  sx={{ "& .MuiTextField-root": { width: "100%" } }}
                  noValidate
                  autoComplete="off"
                >
                  <Stack>
                    <TextField
                      placeholder="Leave comment (optional)"
                      multiline
                    />
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    sx={{ mt: "25px" }}
                  >
                    <Button variant="text" className="btn-text" type="reset">
                      Clear
                    </Button>
                    <Button variant="contained" onClick={handleClose} className="btn-primary ml-2">
                      Save Comment
                    </Button>
                  </Stack>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};
// pulse add comment modal ends

// Multiple comments popup Content start
const getLatestValue = (e,f) => {
  if(e.length > 0 ){
    let record = e.filter(t => f != null && t.engagement_category == f);
    if(record.length > 0 ){
      let latest = _.map(_.sortBy(record, o => Date.parse(o.date)),'update_time').reverse()[0];
      let result = record.find((item) => item.update_time == latest);
      return result;
    }
  }
  return {};
}



// Multiple comments popup Content End

// Multiple comment popup Start
export const CommentListAdd = ({ value, data, openModal, callBackEvent,submitCommentTriggered }) => {
  const { engagementCategoryList, engagementCategoryScore, engagementChangedValue } = useSelector((state) => state.engagementCategory);
  // console.log('data ',data);
  const [getData,setData] = useState();
  useEffect(()=>{
    setData(data)
  },[data])
  // const [getSavedComment, setSavedComment] = useState([]);
  let savedCommentList = [];
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {openModal(true); setOpen(true)};
  const handleClose = () => setOpen(false);
  
  const handleCommentSaveEvent = (value,code) => {  
    let updateComment  = getData?.map(item => {
      if(item.engagement_category === code.engagement_category){
        return ({ ...item , comment: value })
      }
      return ({ ...item})
    })
    setData(updateComment)
    callBackEvent(updateComment);
     // if(e != undefined){
    //   let record = {
    //     "engagement_category": code,
    //     "comment": e,
    //   };
    //   callBackEvent(record);
    // }
  }

  const handleClearAll = () => {

    // console.log('before filter ', data)
    
    let rs = getData.map(obj =>obj.comment != "" ? { ...obj, comment: '' } : obj);
    setData([...rs])
    callBackEvent(rs);
    // console.log(rs)
    
    
    // data = rs;
    // console.log('after filter ', data);
    
  }
  // console.log('update filter ', getData);
  const renderComment = (from,current,prev) => {
    let e = getData
    // console.log('e' , e);
    let result = [];
    // console.log('here is from ',from)
    if(e != undefined && e.length > 0){
      let currentScore,categoryName,prevScore;
      return e.map((item, index) => {
        currentScore = current != '' && current != undefined ? current.find(o => o.engagement_category === item.engagement_category) : false;
        categoryName = from != '' && from != undefined ? from?.find(o => o.code === item.engagement_category)?.name : '';
        prevScore = getLatestValue(prev, item.engagement_category).score
        // console.log(item);
        return (
            <div className="commentBoxWrap" key={index}>
              <Stack className="commentTopInfo">
                <Typography sx={{ color: "#000", fontWeight: "bold" }}>
                  {categoryName}
                </Typography>
                <Box
                  component="span"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography
                    sx={{
                      mr: "4px",
                      fontSize: "12px",
                      color: "#707070",
                    }}
                  >
                    <strong>{!!currentScore ? 'Change : ' : 'Your Rating'}</strong>
                  </Typography>
                  {/* {JSON.stringify(current)} */}
                  {!!currentScore ? 
                  <><Chip
                    sx={{ ml: "4px", fontWeight: "bold", fontSize: "14px" }}
                    label={prevScore}
                    style={{backgroundColor:'transparent'}}
                  /></> : <><Chip
                    sx={{ ml: "4px", fontWeight: "bold", fontSize: "14px" }}
                    label={prevScore}
                    color="primary"
                  /></>}
                  {!!currentScore ?
                  <>
                    <Typography sx={{ fontSize: "14px" }}>
                      <strong> to</strong>
                    </Typography>
                    <Chip
                      sx={{ ml: "4px", fontWeight: "bold", fontSize: "14px" }}
                      label={!!currentScore ? currentScore?.score : 0}
                      color={currentScore.score >= prevScore ? 'success' : 'error'}
                    />
                  </>
                   : ''
                  }
                  
                  {/*color="primary" color="error" color="success" */}
                </Box>
              </Stack>
              <Stack>
                {" "}
                <TextField
                  placeholder="Leave comment (optional)"
                  multiline
                  value={item.comment}
                  onChange={(e)=>{handleCommentSaveEvent(e.target.value,item)}}
                />{" "}
              </Stack>
            </div>
        );
      });
    }
    
    
  };
  return (
    <>
      <Button
        variant="contained"
        onClick={handleOpen}
        className="btn-primary ml-2"
      >
        {value ? value : "Comment"}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 300,
        }}
        className="customPopup"
      >
        <Fade in={open}>
          <Box sx={style}>
            <Card >
              <div className="popupHeader">
                <h4>Add comment</h4>
                <IconButton aria-label="close" onClick={handleClose}>
                  <FontAwesomeIcon icon={faXmark} />
                </IconButton>
              </div>
              <CardContent sx={{ p: "20px 20px" }}>
                { getData?.length > 0 ? 
                renderComment(engagementCategoryList,engagementChangedValue,engagementCategoryScore)
                 : <Skeleton />}
                <Box
                  component="form"
                  sx={{ "& .MuiTextField-root": { width: "100%" } }}
                  noValidate
                  autoComplete="off"
                >
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    sx={{ mt: "25px" }}
                  >
                    <Button
                      variant="text"
                      className="btn-text"
                      disableRipple
                      type="reset"
                      onClick={handleClearAll}
                    > 
                      Clear All
                    </Button>
                    <Button variant="contained" className="btn-primary ml-2" onClick={()=>{submitCommentTriggered('true');handleClose()}}>
                      Submit
                    </Button>
                  </Stack>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

// Multiple comment popup End

// login failed popup starts

export const InfoPopup = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <Button
        variant="contained"
        onClick={handleOpen}
        className="btn-primary ml-2"
      >
        Login Failed
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 300,
        }}
        className="info_popup-wrapper"
      >
        <Fade in={open}>
          <Box sx={style}>
            <Card
              sx={{
                boxShadow: "0px 2px 12px #00000065;",
                borderRadius: "10px",
              }}
            >
              <div className="info_popup-header">
                <IconButton aria-label="close" onClick={handleClose}>
                  <FontAwesomeIcon icon={faXmark} />
                </IconButton>
              </div>
              <CardContent>
                <Box>
                  <FontAwesomeIcon icon={faTriangleExclamation} />
                  <Typography variant="h4">Login Failed</Typography>
                  <Typography>
                    Either your Performance Pro or Microsoft Teams username does
                    not exist or match your record. Please contact your HR
                    administrators. No further action is needed.
                  </Typography>
                </Box>
              </CardContent>
              <CardActions>
                <Box>
                  <Button
                    variant="contained"
                    className="btn-primary"
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                </Box>
              </CardActions>
            </Card>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export const EmployeeComment = ({ data }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const commentData = {
    id: 1,
    comment: "Lorem ipsum dolor sit amet, consectetur  adipiscing elit dolor sit.Maecenas ac lacus vitae nibh  porta lobortis laoreet felis.Lorem ipsum dolor sit amet, consectetur adipiscing elit dolor sit. Maecenas ac lacus vitae nibh porta.",
    category: "Weekly Pulse",
  };
  const comment = commentData;

  return (
    <>
      <Button size="100%" color="black" onClick={handleOpen}>
        {data?.btnText ? data.btnText : (
          <>
            View Comment
            <svg
              height="14pt"
              viewBox="-21 -47 682.66669 682"
              width="14pt"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m552.011719-1.332031h-464.023438c-48.515625 0-87.988281 39.464843-87.988281 87.988281v283.972656c0 48.414063 39.300781 87.816406 87.675781 87.988282v128.863281l185.191407-128.863281h279.144531c48.515625 0 87.988281-39.472657 87.988281-87.988282v-283.972656c0-48.523438-39.472656-87.988281-87.988281-87.988281zm50.488281 371.960937c0 27.835938-22.648438 50.488282-50.488281 50.488282h-290.910157l-135.925781 94.585937v-94.585937h-37.1875c-27.839843 0-50.488281-22.652344-50.488281-50.488282v-283.972656c0-27.84375 22.648438-50.488281 50.488281-50.488281h464.023438c27.839843 0 50.488281 22.644531 50.488281 50.488281zm0 0" />
              <path d="m171.292969 131.171875h297.414062v37.5h-297.414062zm0 0" />
              <path d="m171.292969 211.171875h297.414062v37.5h-297.414062zm0 0" />
              <path d="m171.292969 291.171875h297.414062v37.5h-297.414062zm0 0" />
            </svg>
          </>
        )}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 300,
        }}
        className="employee_comment-popup"
      >
        <Fade in={open}>
          <Box sx={style}>
            <Card>
              <div className="employee_comment-header">
                <IconButton aria-label="close" onClick={handleClose}>
                  <FontAwesomeIcon icon={faXmark} />
                </IconButton>
              </div>
              <CardContent>
                <Stack>
                  <Box>
                    <Stack className="employee_comment-contentHead">
                      <Typography variant="h2">Employee Comment</Typography>
                      <Typography variant="h6">
                        <span>Category:</span> {comment.category}
                      </Typography>
                    </Stack>
                    <Stack>
                      <Typography>
                        <span>03/22/2022:</span>{comment.comment}
                      </Typography>
                    </Stack>
                  </Box>
                  {/* <Box></Box> */}
                </Stack>
              </CardContent>
            </Card>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export const PerformanceNote = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <Button
        className="btn-border"
        onClick={handleOpen}
        sx={{
          minHeight: "42px",
          width: "100%",
          fontSize: "15px",
          color: "#000",
          borderRadius: "0",
          textTransform: "capitalize",
        }}
      >
        Send Performance Note
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 300,
        }}
        className="customPopup"
      >
        <Fade in={open}>
          <Box sx={style}>
            <Card
              sx={{
                boxShadow: "0px 2px 12px #00000065;",
                borderRadius: "10px",
              }}
            >
              <div className="popupHeader">
                <h4>Send Performance Note</h4>
                <IconButton aria-label="close" onClick={handleClose}>
                  <FontAwesomeIcon icon={faXmark} />
                </IconButton>
              </div>
              <CardContent
                sx={{ p: "0px 20px 20px" }}
                className="commentBoxWrap"
              >
                <Stack className="commentTopInfo">
                  <Typography sx={{ color: "#000", fontWeight: "bold" }}>
                    Note For Check-In 12/18/2022
                  </Typography>
                </Stack>
                <Box
                  component="form"
                  sx={{ "& .MuiTextField-root": { width: "100%" } }}
                  noValidate
                  autoComplete="off"
                >
                  <Stack>
                    <TextField
                      placeholder="Add note"
                      multiline
                    />
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    sx={{ mt: "25px" }}
                  >
                    <Button variant="text" className="btn-text ">
                      Clear
                    </Button>
                    <Button variant="contained" className="btn-primary ml-2">
                      Send To Performance Pro
                    </Button>
                  </Stack>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

// login failed popup ends
