import React from 'react';
import './conversation-history.scss';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Fab from '@mui/material/Fab';
import SendIcon from '@mui/icons-material/Send';
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from '@mui/material/IconButton';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';

const ConversationHistory = () => {

  const data = [
    {
      id: 1,
      text: "Daily Discussion",
      date: "12/17/22"
    },
    {
      id: 2,
      text: "Daily Discussion",
      date: "12/16/22"
    },
    {
      id: 3,
      text: "Daily Discussion",
      date: "12/15/22"
    },
    {
      id: 4,
      text: "Daily Discussion",
      date: "12/14/22"
    },
    {
      id: 5,
      text: "Daily Discussion",
      date: "12/13/22"
    },
    {
      id: 6,
      text: "Daily Discussion",
      date: "12/12/22"
    },
    {
      id: 7,
      text: "Daily Discussion",
      date: "12/11/22"
    },
    {
      id: 8,
      text: "Daily Discussion",
      date: "12/10/22"
    },
    {
      id: 9,
      text: "Daily Discussion",
      date: "12/09/22"
    },
    {
      id: 10,
      text: "Daily Discussion",
      date: "12/08/22"
    },
    {
      id: 11,
      text: "Daily Discussion",
      date: "12/07/22"
    },
    {
      id: 11,
      text: "Daily Discussion",
      date: "12/07/22"
    },
    {
      id: 11,
      text: "Daily Discussion",
      date: "12/07/22"
    },
  ];

  const history = data;

  return (
    <Paper className="conversation_history-wrapper">
      <List className="conversation_history-header">
        <ListItem align="center">
          <ListItemText primary="Conversation History"></ListItemText>
        </ListItem>
      </List>
      <List className="conversation_history-list">
        {history.map((item, index) =>
          <>
            <ListItem button key={index}>
              <ListItemText primary={item.text} secondary={item.date}></ListItemText>
            </ListItem>
          </>
        )}
      </List>
    </Paper>
  )
};

export default ConversationHistory;
