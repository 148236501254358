import React, { useEffect, useState } from "react";
import EmployeeProfile from "../components/employee-profile/employee-profile";
import PulseRating from "../components/pulse-rating/pulse-rating";
import EngagementPillars from "../components/engagement-pillars/engagement-pillars";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Charts from "../components/charts/charts";
import Stack from "@mui/material/Stack";
import { makeStyles } from "@mui/styles";
import PillarIndicators from "../components/pillar-indicators/pillar-indicators";
import TopBar from "../components/top-bar/top-bar";


const EmployeeDashboard = () => {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  function getWindowSize() {
    const { innerWidth } = window;
    return { innerWidth };
  }

  return (
    <>
    <div className="rootWrapper">
      <div className="breadcrumb-wrap">
        <TopBar />
      </div>
      <Container maxWidth="auto" className="rootContainer">
      
        <Grid>
          <Stack className="topRow" sx={{ display: "grid" }}>
            <Grid item className="topLeft">
              <EmployeeProfile />
              {windowSize.innerWidth >= 992 &&
                <PillarIndicators />
              }
            </Grid>
            <Grid item className="topRight">
              <div className="topRight-inner">
                <PulseRating />
                <Charts />
              </div>
              {windowSize.innerWidth >= 992 &&
                <div className="topRight-inner">
                  <EngagementPillars />
                </div>
              }
            </Grid>
          </Stack>
          {windowSize.innerWidth <= 991 &&
            <Stack className="bottomRow">
              {windowSize.innerWidth <= 991 &&
                <PillarIndicators />
              }
              <EngagementPillars />
            </Stack>
          }
        </Grid>
      </Container>
    </div>

    </>
  );
};

EmployeeDashboard.propTypes = {};

EmployeeDashboard.defaultProps = {};

export default EmployeeDashboard;
