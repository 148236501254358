import React from "react";
import "./change-tracking.scss";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import employeeImg1 from "../../assets/images/joanie-doe.png";
import employeeImg2 from "../../assets/images/samantha-smith.png";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Chip from "@mui/material/Chip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation } from "@fortawesome/free-solid-svg-icons";

const ChangeTracking = () => {
  const employee_data = [
    {
      id: 1,
      pillarName: "Sense of Purpose",
      lastScore: 4,
      currentScore: 9,
    },
    {
      id: 1,
      pillarName: "Feedback",
      lastScore: 4,
      currentScore: 2,
    },
    {
      id: 1,
      pillarName: "Opportunity",
      lastScore: 4,
      currentScore: 8,
    },
    {
      id: 1,
      pillarName: "Recognition",
      lastScore: 4,
      currentScore: 8,
    },
    {
      id: 1,
      pillarName: "Rewards",
      lastScore: 6,
      currentScore: 5,
    },
    {
      id: 1,
      pillarName: "Collaboration",
      lastScore: 5,
      currentScore: 5,
    },
    {
      id: 1,
      pillarName: "Innovation",
      lastScore: 4,
      currentScore: 3,
    },
    {
      id: 1,
      pillarName: "Manager Guidance",
      lastScore: 8,
      currentScore: 7,
    },
    {
      id: 1,
      pillarName: "Work-life Balance",
      lastScore: 4,
      currentScore: 6,
    },
  ];
  const data = employee_data;
  return (
    <>
      <Card className="change_tracking-wrapper">
        <CardHeader title="Change Tracking" />
        <CardContent>
          <List>
            {employee_data?.map((data, i) => (
              <>
                <ListItem key={i}>
                  <Stack
                    // className="change_tracking-details"
                    className={
                      data.currentScore < data.lastScore
                        ? "change_tracking-details low-score"
                        : "change_tracking-details"
                    }
                  >
                    <Box component="span" className="details-left">
                      {data.currentScore < data.lastScore && (
                        <>
                          <FontAwesomeIcon icon={faExclamation} />
                        </>
                      )}
                      <Typography variant="h6">{data.pillarName}</Typography>
                      {data.comment && (
                        <Button size="100%" color="black">
                          (Comment Added)
                        </Button>
                      )}
                    </Box>
                    <Box component="span" className="details-right">
                      <Typography>{data.lastScore} to</Typography>
                      <Chip
                        label={data.currentScore}
                        color="primary"
                        className={
                          data.currentScore < data.lastScore ? "red" : "green"
                        }
                      />
                    </Box>
                  </Stack>
                </ListItem>
              </>
            ))}
          </List>
        </CardContent>
      </Card>
    </>
  );
};

ChangeTracking.propTypes = {};

ChangeTracking.defaultProps = {};

export default ChangeTracking;
