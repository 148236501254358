import { configureStore } from "@reduxjs/toolkit";
import { engagementCategorySlice } from "./reducer/engagementPillars";

export const store = configureStore({
    reducer: {
        engagementCategory : engagementCategorySlice.reducer,
        // engagementCategoryScore : engagementCategorySlice.reducer
    }
})

export const RootState = store.getState;
export const AppDispatch = store.dispatch;