import React from 'react';
import './chat.scss';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Fab from '@mui/material/Fab';
import SendIcon from '@mui/icons-material/Send';
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from '@mui/material/IconButton';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFaceSmile } from "@fortawesome/free-regular-svg-icons";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { faNoteSticky } from "@fortawesome/free-solid-svg-icons";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { faReply } from "@fortawesome/free-solid-svg-icons";



const Chat = () => {

  const chatData = [
    {
      id: 1,
      name: "John Doe",
      type: "personal_chat",
      messages: [
        {
          id: 1,
          type: "message",
          date: "2020-01-01T00:00:02",
          from: "Samantha Smith",
          from_id: 1,
          text: "A1.Sed et ultrices dolor. Fusce vulputate pretium massa?"
        },
        {
          id: 2,
          type: "message",
          date: "2020-01-01T00:00:04",
          from: "Samantha Smith",
          from_id: 2,
          text: "Fireworks!"
        },
        {
          id: 3,
          type: "message",
          date: "2020-01-01T00:00:05",
          from: "John Doe",
          from_id: 3,
          text: "You're a minute late!"
        },
      ],
    },
  ]
  const data = chatData;
  // console.log(data)
  return (
    <>
      <Paper className="chat_box-wrapper">
        <List className="chat_box-header">
          <ListItem
            // button 
            key="RemySharp">
            <ListItemIcon>
              <Avatar alt="Remy Sharp" src="" />
            </ListItemIcon>
            <ListItemText primary={
              <>
                Daily Discussion 12/12/22
                <IconButton className='edit-iconButton'>
                  <FontAwesomeIcon icon={faPenToSquare} />
                </IconButton>
              </>
            }
              secondary="John Doe, You"></ListItemText>
          </ListItem>
        </List>
        <Box className="chat_box-body">
          <Stack className="chat_box-body-messagesWrap" direction="column-reverse">
            <List className="chat_box-body-messagesList">
              <ListItem key="1" align="right">
                <Grid container>
                  <Grid item xs={12}>
                    <ListItemText secondary="09:30"></ListItemText>
                  </Grid>
                  <Grid item xs={12}>
                    <ListItemText primary="Q1. Sed et ultrices dolor. Fusce vulputate pretium"></ListItemText>
                    <ListItemText primary="Q2. Sed et ultrices dolor. Fusce vulputate pretium"></ListItemText>
                    <ListItemText primary="Q3. Sed et ultrices dolor. Fusce vulputate pretium"></ListItemText>
                    <ListItemText primary="Q4. Sed et ultrices dolor. Fusce vulputate pretium massa?"></ListItemText>
                  </Grid>
                </Grid>
                <Avatar alt="John Doe" src="" />
              </ListItem>
              <ListItem key="2" align="left">
                <Grid container>
                  <Grid item xs={12}>
                    <ListItemText secondary="09:31"></ListItemText>
                  </Grid>
                  <Grid item xs={12}>
                    <ListItemText
                      className="hasReply"
                      primary={
                        <>
                          <div className="replyFor">
                            <FontAwesomeIcon icon={faReply} />
                            Q1. Sed et ultrices dolor. Fusce vulputate pretium massa?
                          </div>
                          A1. Sed et ultrices dolor. Fusce vulputate pretium
                        </>
                      }></ListItemText>

                    <ListItemText primary="A1. Sed et ultrices dolor. Fusce vulputate pretium"></ListItemText>
                    <ListItemText primary="A1. Sed et ultrices dolor. Fusce vulputate pretium"></ListItemText>
                    <ListItemText primary="A1. Sed et ultrices dolor. Fusce vulputate pretium massa?"></ListItemText>
                  </Grid>
                </Grid>
                <Avatar alt="John Doe" src="" />
              </ListItem>
            </List>
          </Stack>

          <Stack direction="row" className="chat_box-body-inputWrapper">
            <OutlinedInput placeholder="Type a message here" fullWidth
              startAdornment={
                <InputAdornment position="start" title="Attach file">
                  <IconButton>
                    <FontAwesomeIcon icon={faPaperclip} />
                  </IconButton>
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton>
                    <FormatColorTextIcon />
                  </IconButton>
                  <IconButton>
                    <FontAwesomeIcon icon={faFaceSmile} />
                  </IconButton>
                  <IconButton>
                    <FontAwesomeIcon icon={faNoteSticky} />
                  </IconButton>
                </InputAdornment>
              }
            />
            <Fab aria-label="add"><SendIcon /></Fab>
          </Stack>
        </Box>
      </Paper>
    </>
  );

};

export default Chat;
