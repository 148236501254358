import React, { useEffect, useMemo, useState } from "react";
// import PropTypes from "prop-types";
import "./engagement-pillars.scss";
import Stack from "@mui/material/Stack";

import Box from "@mui/material/Box";

import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { styled } from "@mui/material/styles";

import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";

import { EmployeeComments, CommentListAdd } from "../modals/modals";

import { engagementCategoryListAction, engagementCategoryScoreAction, sendEngagementCategoryRequestAction, engagementCategoryCommentsAction } from "../../actions/engagement";
import { setengagementCategoryList, setengagementCategoryScore, setengagementCategoryComments, setengagementChengedValue } from "../../reducer/engagementPillars";
// import Skeleton from 'react-loading-skeleton'
import Skeleton from '@mui/material/Skeleton';
// import 'react-loading-skeleton/dist/skeleton.css'
import { PillarCard } from "./pillarCard";

import CircularProgress from '@mui/material/CircularProgress';

import * as _ from "lodash";
import { result } from "lodash";

import { useSnackbar } from 'notistack';


// import PillarIndicators from "../pillar-indicators/pillar-indicators"

const EngagementPillars = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  let dataCard = [
    {
      target: 8,
      current: 7,
      comments: [],
      title: "Sense of Purpose",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit dolor sit. Maecenas ac lacus vitae nibh porta lobortis laoreet felis. Lorem ipsum dolor sit amet, consectetur adipiscing elit dolor sit.",
    },
    {
      target: 8,
      current: 6,
      comments: [],
      title: "Feedback",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit dolor sit. Maecenas ac lacus vitae nibh porta lobortis laoreet felis. Lorem ipsum dolor sit amet, consectetur adipiscing elit dolor sit.",
    },
    {
      target: 5,
      current: 7,
      comments: [],
      title: "Opportunity",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit dolor sit. Maecenas ac lacus vitae nibh porta lobortis laoreet felis. Lorem ipsum dolor sit amet, consectetur adipiscing elit dolor sit.",
    },
    {
      target: 8,
      current: 7,
      comments: [],
      title: "Recognition",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit dolor sit. Maecenas ac lacus vitae nibh porta lobortis laoreet felis. Lorem ipsum dolor sit amet, consectetur adipiscing elit dolor sit.",
    },
    {
      target: 8,
      current: 7,
      comments: [],
      title: "Rewards",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit dolor sit. Maecenas ac lacus vitae nibh porta lobortis laoreet felis. Lorem ipsum dolor sit amet, consectetur adipiscing elit dolor sit.",
    },
    {
      target: 9,
      current: 2,
      comments: [],
      title: "Collaboration",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit dolor sit. Maecenas ac lacus vitae nibh porta lobortis laoreet felis. Lorem ipsum dolor sit amet, consectetur adipiscing elit dolor sit.",
    },
    {
      target: 6,
      current: 7,
      comments: [],
      title: "Innovation",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit dolor sit. Maecenas ac lacus vitae nibh porta lobortis laoreet felis. Lorem ipsum dolor sit amet, consectetur adipiscing elit dolor sit.",
    },
    {
      target: 4,
      current: 4,
      comments: [],
      title: "Manager Guidance",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit dolor sit. Maecenas ac lacus vitae nibh porta lobortis laoreet felis. Lorem ipsum dolor sit amet, consectetur adipiscing elit dolor sit.",
    },
    {
      target: 5,
      current: 8,
      comments: [],
      title: "Work Life Balance",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit dolor sit. Maecenas ac lacus vitae nibh porta lobortis laoreet felis. Lorem ipsum dolor sit amet, consectetur adipiscing elit dolor sit.",
    },
  ];
  const [open, setOpen] = useState("");
  const pillarsScore = [];
  const commentsSaved = [];
  
  const [getPillarsScore,setPillarsScore] = useState();
  const dispatch = useDispatch();
  // ========= Getting Engagement Category List from State Store =========
  const { engagementCategoryList, engagementCategoryScore, engagementCategoryComments, engagementChangedValue } = useSelector((state) => state.engagementCategory);
  // ========== Dispatch Function for Engagement Category List =========
  const engagementCategoryListDispatch = async () => {
    let value = await engagementCategoryListAction('category/list');
    dispatch(setengagementCategoryList(value));
  }
  // ========== Dispatch Function for Employee Score =========
  const engagementCategoryScoreDispatch = async () => {
    let value = await engagementCategoryScoreAction('emp/score');
    dispatch(setengagementCategoryScore(value));
  }
  // ========== Dispatch Function for Employee Comment =========
  const engagementCategoryCommentsDispatch = async () => {
    let value = await engagementCategoryCommentsAction('emp/comment');
    dispatch(setengagementCategoryComments(value));
  }
 
  useEffect(() => {
    engagementCategoryListDispatch();
    engagementCategoryScoreDispatch();
    engagementCategoryCommentsDispatch();
  },[])

  

const getLatestValue = (e,f) => {
    if(e.length > 0 ){
      let record = e.filter(t => f != null && t.engagement_category == f);
      if(record.length > 0 ){
        let latest = _.map(_.sortBy(record, o => Date.parse(o.date)),'update_time').reverse()[0];
        let result = record.find((item) => item.update_time == latest);
        return result;
      }
    }
    return {};
  }
  // console.log('pillarsScore ',  pillarsScore)
  const pillarScorePush = (e) =>{
   const foundIndex = pillarsScore.findIndex((el) => el.engagement_category === e.engagement_category)
   if(foundIndex === -1){
     pillarsScore.push(e);
    }else{
      pillarsScore[foundIndex] = e;
    }
    // dispatch(setengagementChengedValue(pillarsScore));
  }
  const handleCallBackEvent = (e) =>{
    if(_.isEmpty(e) == false){
       pillarScorePush(e);
    }
    // console.log('engagementChangedValue ', engagementChangedValue);
  }
  const commentsSavedPush = (e) =>{
    const foundIndex = commentsSaved.findIndex((el) => el.engagement_category === e.engagement_category)
    if(foundIndex === -1){
      commentsSaved.push(e);
     }else{
       commentsSaved[foundIndex] = e;
     }
     // dispatch(setengagementChengedValue(pillarsScore));
   }
  const handleCommentListSave = (e) => {
    if(_.isEmpty(e) == false){
      commentsSavedPush(e);
   }
  }

  const handleUpdateData = () =>{
    engagementCategoryCommentsDispatch();
    dispatch(setengagementChengedValue(pillarsScore));
    // console.log('engagementChangedValue ', engagementChangedValue);
  }

  // console.log('pillarsScore ', pillarsScore)
  const handleCallBackCommentEvent = async (e) => {
    if(_.isEmpty(e) == false){
      let payload = [e];
        let result = await sendEngagementCategoryRequestAction('emp/comment', payload);
        if(result.status.toLowerCase() == 'ok'){
          let variant = 'success';
          enqueueSnackbar('Submit successfully', {variant});
          pillarsScore.length = 0;
        }else if(result.status.toLowerCase() == 'error'){
          let variant = 'error';
          enqueueSnackbar(`Submit error (Reason : ${result.error_message})`, {variant});
        }
      }else{
        let variant = 'error';
          enqueueSnackbar(`No change found`, {variant});
      }
  }
  // const check = React.useMemo(()=>{console.log('here is the effect pillarsScore ', pillarsScore);},[handleCallBackEvent]);
  const sendEngagementPillarValue = async () =>{
    let payload = engagementChangedValue;

    // console.log('pillarsScore ',pillarsScore);
    if(payload.length > 0) {
      let result = await sendEngagementCategoryRequestAction('emp/score', payload);
      if(result.status.toLowerCase() == 'ok'){
        let variant = 'success';
        enqueueSnackbar('Submit score successfully', {variant});
        pillarsScore.length = 0;
        dispatch(setengagementChengedValue([]));
      }else if(result.status.toLowerCase() == 'error'){
        let variant = 'error';
        enqueueSnackbar(`Submit error (Reason : ${result.error_message})`, {variant});
      }
    }else{
      let variant = 'error';
        enqueueSnackbar(`No score change found`, {variant});
    }
  }
  const handleCommentSubmitEvent = () => {
    // console.log('commentsSaved ',commentsSaved);
    // console.log('engagementChangedValue ',engagementChangedValue);
    
    if(commentsSaved.length > 0){
      sendEngagementPillarComments();
      engagementCategoryCommentsDispatch();
    }
    // if(engagementChangedValue != ''){
      sendEngagementPillarValue();
    // }
    
    // engagementCategoryScoreDispatch();
    
  }
  const sendEngagementPillarComments = async () =>{
    // let payload = pillarsScore;

    // console.log('pillarsScore ',pillarsScore);
    if(commentsSaved.length > 0) {
      let result = await sendEngagementCategoryRequestAction('emp/comment', commentsSaved[0]);
      if(result.status.toLowerCase() == 'ok'){
        let variant = 'success';
        enqueueSnackbar('Submit comments successfully', {variant});
        // commentsSaved.length = 0;
      }else if(result.status.toLowerCase() == 'error'){
        let variant = 'error';
        enqueueSnackbar(`Submit error (Reason : ${result.error_message})`, {variant});
      }
    }else{
      let variant = 'error';
        enqueueSnackbar(`No comment change found`, {variant});
    }
  }
  return (
    <>
      <Box className="engagement_pillars-wrapper">
        <ClickAwayListener onClickAway={() => { setOpen(""); }} >
          {(!! engagementCategoryList.length &&
            engagementCategoryList.length != undefined) ?
              <Box className="engagementPillar-sliderWrapper">
                {_.sortBy(engagementCategoryList, "sort").map((item, index) =>
                (!! engagementCategoryScore.length &&
                  engagementCategoryScore.length != undefined) ? 
                  <PillarCard 
                    data={item} 
                    score={
                      getLatestValue(engagementCategoryScore, item.code)
                    } 
                    comments={
                      !! engagementCategoryComments.length && engagementCategoryComments.length != undefined ?
                      getLatestValue(engagementCategoryComments, item.code) : ''
                    }
                    callBackEvent={handleCallBackEvent}
                    callBackCommentEvent={handleCallBackCommentEvent}
                    index={index} 
                    key={index} 
                  /> 
                  : 
                  <PillarCard />
                  )
                }
              </Box>
            :
              <Box className="engagementPillar-sliderWrapper">
                <PillarCard />
                <PillarCard />
                <PillarCard />
                <PillarCard />
                <PillarCard />
              </Box>
          }
        </ClickAwayListener>
        <Box className="engagement_pillars-btnWrap">
          <Button
            variant="outlined"
            sx={{
              borderRadius: "50px",
              textTransform: "capitalize",
              minWidth: "200px",
              minHeight: "45px",
              fontWeight: "bold",
            }}
            onClick={sendEngagementPillarValue}
          >
            Submit
          </Button>
          <CommentListAdd value="Add Comment & Submit" data={engagementCategoryComments} openModal={handleUpdateData} callBackEvent={handleCommentListSave} submitCommentTriggered={handleCommentSubmitEvent} />
        </Box>
      </Box>
    </>
  );
};

export default EngagementPillars;
