import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTeams } from "msteams-react-base-component";

import { Route, Outlet, Navigate } from "react-router-dom";
import Cookie from "js-cookie";


const Protected = ({ role }) => {

    // const login = Cookie.get('token') != undefined ? true : false;
    const user = Cookie.get('role') != undefined ? (Cookie.get('role') == true ? 'manager' : 'employee') : false;

    useEffect(()=>{
        if(Cookie.get('token') != undefined){
            <Navigate to='/login' />
        }
    });
    
    let auth = Cookie.get('token') != undefined ? true : false;

    return(auth ? ( user == role ? <Outlet /> : <Navigate to='/login' /> ) : <Navigate to='/login' />)
}

export const LoginRoute = ({currentUser}) => {
    const [{ inTeams, context }] = useTeams();
    // if((inTeams != undefined && inTeams == false)  && ( process.env.NODE_ENV === 'production')){
    //     return <Navigate to="/error" />
    // }
    const user = Cookie.get('role') != undefined ? (Cookie.get('role') == true ? 'manager' : 'employee') : false;
    return(user ? <Navigate to='/employee-dashboard' /> : <Outlet />)
}
export const AuthGuard = () =>{
    const [{ inTeams, context }] = useTeams();
    // if((inTeams != undefined && inTeams == false)  && ( process.env.NODE_ENV === 'production')){
    //     return <Navigate to="/error" />
    // }
    let auth = Cookie.get('token') != undefined ? true : false;
    return(auth ? <Outlet /> : <Navigate to='/login' />)
}
export default Protected;