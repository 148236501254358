import React from "react";
// import { json } from "react-router-dom";
import Button from "@mui/material/Button";
import "./charts.scss";
import {
  ResponsiveContainer,
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Area,
} from "recharts";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
// import Tooltip from "@mui/material/Tooltip";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <>
        <div className="custom-tooltip">
          <span className="label">{payload[0].payload.trend}</span>
          <span className="date">{payload[0].payload.date}</span>
        </div>
      </>
    );
  }

  return null;
};
const charts = () => {
  // console.log(window.outerWidth ,"height...")
  const data = [
    {
      time: "Week 5",
      trend: 4,
      date: "1/18/22",
    },
    {
      time: "Week 10",
      trend: 3.5,
      date: "2/18/22",
    },
    {
      time: "Week 15",
      trend: 3,
      date: "3/18/22",
    },
    {
      time: "Week 20",
      trend: 4.5,
      date: "4/18/22",
    },
    {
      time: "Week 25",
      trend: 7,
      date: "5/18/22",
    },
    {
      time: "Week 30",
      trend: 6,
      date: "6/18/22",
    },
    {
      time: "Week 35",
      trend: 3,
      date: "7/18/22",
    },
    {
      time: "Week 40",
      trend: 4.1,
      date: "8/18/22",
    },
    {
      time: "Week 45",
      trend: 5.5,
      date: "9/18/22",
    },
    {
      time: "Week 50",
      trend: 3.5,
      date: "10/18/22",
    },
    {
      time: "Week 50",
      trend: 3.5,
      date: "10/18/22",
    },
    {
      time: "Week 40",
      trend: 4.1,
      date: "8/18/22",
    },
    {
      time: "Week 45",
      trend: 5.5,
      date: "9/18/22",
    },
    {
      time: "Week 50",
      trend: 3.5,
      date: "10/18/22",
    },
    {
      time: "Week 50",
      trend: 3.5,
      date: "10/18/22",
    },
  ];
  const handleFilterEvent = (e) => {
    console.log("event clicked " + e.currentTarget.dataset.value);
  };
  return (
    <>
      <Card className="pulse_chart-wrapper">
        <CardContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
              p: "12px 24px",
            }}
          >
            <Stack direction="row" spacing={1}>
              <Typography sx={{ fontSize: "17px", fontWeight: "bold",margin: "5px 0px" }}>
                Pulse Trend <Typography component="span">Year: 2022</Typography>
              </Typography>
            </Stack>
            <Stack direction="row" className="graphButtonRow" spacing={1}>
              <Button variant="contained" onClick={handleFilterEvent} data-value="month">Month</Button>
              <Button variant="contained" color="neutral" onClick={handleFilterEvent} data-value="month">Month</Button>
              <Button variant="contained" color="neutral" onClick={handleFilterEvent} data-value="year">Year</Button>
            </Stack>
          </Box>
          {!!data.length ? (
            <>
              <Box>
                <ResponsiveContainer width="100%" height={285}>
                  <AreaChart
                    data={data}
                    margin={{ top: 10, right: 40, left: -25, bottom: 0 }}
                  >
                    <defs>
                      <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                        <stop
                          offset="5%"
                          stopColor="#F49323"
                          stopOpacity={0.8}
                        />
                        <stop
                          offset="95%"
                          stopColor="#F49323"
                          stopOpacity={0}
                        />
                      </linearGradient>
                    </defs>
                    <XAxis
                      xAxisId="0"
                      dataKey="time"
                      
                      tick={{ fill: "#6C6C6C" }}
                      label={{ fill: "#6c6c6c" }}
                      tickLine={{ stroke: "#0000" }}
                      style={{
                        fontSize: "12px",
                      }}
                    />
                    <XAxis
                      xAxisId="1"
                      dataKey="date"
                      fill="red"
                      
                      tick={{ fill: "#9e9e9e" }}
                      tickLine={{ stroke: "#0000" }}
                      style={{
                        fontSize: "11px",
                        transform: "translateY(-12px)",
                      }}
                    />
                    <YAxis
                      // tick={{ fill: "red" }}
                      tickLine={{ stroke: "#0000" }}
                    />
                    <CartesianGrid strokeSolidarray="3 3" />
                    <Tooltip content={<CustomTooltip />} />
                    <Area
                      type="monotone"
                      dataKey="trend"
                      stroke="#F49323"
                      strokeWidth={2.5}
                      fillOpacity={1}
                      fill="url(#colorPv)"
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </Box>
            </>
          ) : (
            <>
              <Box
                sx={{
                  p: 2,
                  width: "100%",
                  height: "290px",
                  backgroundColor: "#F5F5F5",
                  boxSizing: "border-box",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography sx={{ fontSize: "22px", color: "#898989" }}>
                  Not Enough Data
                </Typography>
              </Box>
            </>
          )}
        </CardContent>
      </Card>
    </>
  );
};
export default charts;
