import React from "react";
import PropTypes from "prop-types";
import "./employee-grid.scss";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Slider from "@mui/material/Slider";
import "./../../main.scss";
import employeeImg from "../../assets/images/samantha-smith.png";
import Grid from "@mui/material/Grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation } from "@fortawesome/free-solid-svg-icons";
import CardHeader from "@mui/material/CardHeader";

import Employee from "../employee/employee";

let employeeGridInfo = [
  {
    title: "Employee Responses: How I feel about my work this week?",
    employeeCards: [
      {
        name: "Samantha Smith",
        duration: 244,
        employeeImage: employeeImg,
        lastWeekScore: 9,
        currentWeekScore: 4,
        notification: true,
        jobTitle: "",
      },
      {
        name: "John Doe",
        duration: 968,
        employeeImage: "",
        lastWeekScore: 8,
        currentWeekScore: 5,
        notification: true,
        jobTitle: "",
      },
      {
        name: "John Longlastname",
        duration: 244,
        employeeImage: employeeImg,
        lastWeekScore: 7,
        currentWeekScore: 8,
        notification: false,
        jobTitle: "",
      },
      {
        name: "Alex Johnson",
        duration: 968,
        employeeImage: "",
        lastWeekScore: 5,
        currentWeekScore: 9,
        notification: false,
        jobTitle: "",
      },
      {
        name: "Samantha Smith",
        duration: 244,
        employeeImage: employeeImg,
        lastWeekScore: 9,
        currentWeekScore: 4,
        notification: false,
        jobTitle: "",
      },
      {
        name: "John Doe",
        duration: 968,
        employeeImage: employeeImg,
        lastWeekScore: 8,
        currentWeekScore: 5,
        Notification: false,
        jobTitle: "",
      },
      {
        name: "John Doe",
        duration: 968,
        employeeImage: employeeImg,
        lastWeekScore: 8,
        currentWeekScore: 8,
        notification: false,
        jobTitle: "",
      },
      {
        name: "John Doe",
        duration: 244,
        employeeImage: employeeImg,
        lastWeekScore: 9,
        currentWeekScore: 9,
        notification: false,
        jobTitle: "",
      },
      {
        name: "John Doe",
        duration: 968,
        employeeImage: employeeImg,
        lastWeekScore: 5,
        currentWeekScore: 5,
        notification: false,
        jobTitle: "",
      },
    ],
  },
];

const EmployeeGrid = () => (
  <Card
    className="employee-grid-wrapper"
    sx={{
      boxShadow: "0px 0px 6px rgb(0 0 0 / 10%)",
      borderRadius: "10px",
      textAlign: "center",
      pb: "20px",
      marginBottom: "25px",
    }}
  >
    {employeeGridInfo.map((item) => {
      return (
        <>
          <CardHeader title={item.title} />
          <Grid container columnSpacing={{ xs: 3, sm: 3, lg: 2, xl:3 }} rowSpacing={2}
            className="employee-List"
          >
            {item.employeeCards.map((card, index) => {
              return (
                <Grid item xs={12} sm={4} md={3} lg={4} xl={3} className="employeeWrap" key={index}>
                  <Box
                    className={
                      card.currentWeekScore < card.lastWeekScore
                        ? "employeeCard error"
                        : "employeeCard"
                    }
                  >
                    <Employee
                      data={{
                        name: card.name,
                        src: card.employeeImage,
                        settings: {
                          min: 0,
                          step: 1,
                          max: 10,
                          disabled: "true",
                          defaultValue: card.currentWeekScore,
                          valueLabelDisplay: "on",
                        },
                        lastWeekScore: card.lastWeekScore,
                        jobTitle: card.jobTitle,
                        currentWeekScore: card.currentWeekScore,
                      }}
                    />
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </>
      );
    })}
  </Card>
);

export default EmployeeGrid;
