import axios from "axios";
import Cookie from "js-cookie";

const request = axios.create({
    baseURL: `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_TENANT_ID}/`,
    headers:{
        'Content-Type': 'text/plain',
    },
});

request.interceptors.request.use((config)=>{
    const token = Cookie.get('token');
    if(token){
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});
export function apiRequest(base,query){
    if(query == null){
        return request(base);
    }else{
        return axios.get(base + query);
    }
}
export default request;