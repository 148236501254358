import React from 'react';
import './employee-profile.scss';
import employeeImg from "../../assets/images/samantha-smith.png";
import Employee from "../employee/employee";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";

const EmployeeProfile = () => {

  const employeeData = {
    name: "Samantha Smith",
    duration: 244,
    employeeImage: employeeImg,
    lastWeekScore: 9,
    currentWeekScore: 4,
    notification: true,
    jobTitle: "Job Title Here",
  };

  const info = employeeData;

  return (
    <Box className="employee_card-wrapper">
      <Employee data={{
        name: info.name,
        src: info.employeeImage,
        jobTitle: info.jobTitle,

        settings: {
          min: 0,
          step: 1,
          max: 10,
          disabled: "true",
          defaultValue: info.currentWeekScore,
          valueLabelDisplay: "on",
        },
        lastWeekScore: info.lastWeekScore,
        currentWeekScore: info.currentWeekScore,
      }}
      />
    </Box>
  )
};

export default EmployeeProfile;
