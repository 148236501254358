import React from "react";
// import PropTypes from "prop-types";
import "./range-slider.scss";
import Slider from "@mui/material/Slider";

const RangeSlider = ({settings,eventTriggered,index}) => {
//   const handleChange = (event, newValue) => {
//     props.eventTriggered = newValue
// };
//   const settings = props.settings;
  return (
    <>
      <Slider
        className={settings.className}
        sx={{
          boxShadow: "0px 2px 2px #00000029",
          padding: "0 !important",
          width: settings.width,
          maxWidth: settings.maxWidth,
          height: settings.height,
          color: settings.color,
          borderRadius: settings.borderRadius,
          background: "#225FA0",
          // pointerEvents: 'none' 
        }}
        onChange={(e,newValue) => {eventTriggered(newValue)}}
        orientation={settings.orientation}
        min={settings.min}
        max={settings.max}
        defaultValue={settings.defaultValue}
        // aria-label="Default"
        valueLabelDisplay={settings.valueLabelDisplay}
        disabled={settings.disabled}
        marks={settings.marks}  
        disableSwap={settings.disableSwap} 
        
      />
    </>
  );
};

export default RangeSlider;
