import React from "react";
import "./employee.scss";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import VideocamIcon from "@mui/icons-material/Videocam";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Slider from "../range-slider/range-slider";
import { useLocation } from 'react-router-dom'
import { EmployeeComment, PerformanceNote } from "../modals/modals";

const Employee = ({ data }) => {

  function stringAvatar(name) {
    return {
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }

  const location = useLocation();

  return (
    <>
      <Box>
        <div className="profileInfoWrap">
          { data.src ? (
              <Avatar alt={data.name} src={data.src} />
            ) : (
              
              <Avatar {...stringAvatar(data.name)} />
            )
          }
          <Typography className="employeeName" variant="h6" sx={{ marginTop: "15px" }}>
            {data.name}
          </Typography>
          { data.jobTitle ? (<p className="jobTitle"><em>{data.jobTitle}</em></p>) : ("") }
          {location.pathname != '/employee-dashboard' &&
            <>
              <Slider settings={data.settings} />
              <div className="font14"><strong>Weekly Pulse</strong></div>
            </>
          }
          {location.pathname == '/manager-dashboard' &&
            <span className="previousInfo">Previous: {data.lastWeekScore} ({(data.lastWeekScore) - (data.currentWeekScore)})</span>
          }
          {location.pathname == '/profile' &&
            <EmployeeComment />
          }
        </div>
        <div className="profileBtnsWrap">
          {location.pathname != '/manager-chat' &&
          <Button
            variant="outlined"
            className="btn-primary btn-border" href="/manager-chat" >
            View Conversation
          </Button>
          }
          {location.pathname == '/manager-chat' &&
            <Button variant="contained" className="btn-primary">Start New Conversation</Button>
          }
          {location.pathname == '/manager-dashboard' &&
            <Button variant="contained" href="/profile" target="_blank" className="btn-primary">Profile</Button>
          }
          {location.pathname != '/manager-dashboard' &&
            <Button
              variant="contained"
              className="btn-primary"
              endIcon={<VideocamIcon />}
            >
              Start Meeting
            </Button>
          }
          {location.pathname == '/manager-chat' &&
            <PerformanceNote />
          }
        </div>
      </Box>
    </>
  )
};

export default Employee;
