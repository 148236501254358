import React from "react";
import EmployeeGrid from "../components/employee-grid/employee-grid";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import Notifications from "../components/notifications/notifications";
// import {InfoPopup} from "../components/modals/modals";
import TopBar from "../components/top-bar/top-bar";

const useStyles = makeStyles({
  root: {
    border: 0,
    borderRadius: 8,
  },
});


const ManagerDashboard = () => {

  const classes = useStyles();
  return (
    <>
    <div className="rootWrapper">
      <div className="breadcrumb-wrap">
        {/* <h4 className="breadcrumb-title"><strong>Manager Dashboard</strong></h4> */}
        <TopBar />
      </div>
      <Container maxWidth="auto" className="rootContainer" >
        <Grid container spacing={4}>
          <Grid item xs={12} lg={6} className="bg-white" >
            <EmployeeGrid />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Notifications />
            {/* <InfoPopup /> */}
          </Grid>
        </Grid>
      </Container>
    </div>

    </>
  );
};

export default ManagerDashboard;
